<template>
	<div class="collapse-box">
		<div class="collapse-title">
			<el-checkbox
				v-if="!isUpdate"
				v-model="costList.checked"
				@change="change"
				style="margin-right:5px"
			></el-checkbox
			>{{ costList.policyName }}
			<span class="collapse-remark">{{ costList.policyRemark }}</span>
			<template v-if="isUpdate">
				<strong @click="showBox = !showBox">展开成本</strong>
			</template>
		</div>
		<template v-if="isUpdate">
			<div v-if="showBox" class="collapse-info">
				<div style="padding-bottom: 10px;">
					<span>税点&emsp;</span>
					<el-select
						v-model="costList.taxPoint"
						placeholder="请选择开关"
						:disabled="!canChangeTaxPoint"
					>
						<el-option
							v-for="i in costList.taxPointList"
							:label="i.text"
							:value="i.value"
							:key="i.value"
						></el-option>
					</el-select>
				</div>
				<table class="rate-table" style="width:950px">
					<tr>
						<th>业务类型</th>
						<th>卡类型</th>
						<th>T1成本（比例：%，封顶：元）</th>
						<th>T0成本（%）</th>
						<th>T0提现费成本（元）</th>
						<th>调价分成</th>
					</tr>
					<tr v-for="item in costList.costList" :key="item.id">
						<td>{{ item.payTypeName }}</td>
						<td>{{ item.cardType | formateCardType }}</td>
						<td>
							<div style="display: inline-block;">
								<span
									v-if="
										item.payTypeCode == 'POS' &&
											item.cardType == '2'
									"
									>比例：</span
								><CostInput
									v-model="item.t1Rate"
									:name="'t1Rate' + item.id"
									@getStatus="getStatus"
									:minVal="item.t1MinRate"
									:maxVal="item.t1MaxRate"
								></CostInput>
							</div>
							<div
								v-if="
									item.payTypeCode == 'POS' &&
										item.cardType == '2'
								"
							>
								封顶<CostInput
									v-model="item.t1TopFee"
									:minVal="item.t1MinTopFee"
									:name="'t1MinTopFee' + item.id"
									@getStatus="getStatus"
									:maxVal="item.t1MaxTopFee"
								></CostInput>
							</div>
							<template
								v-if="
									costList.allowanceCost &&
										item.payTypeCode == 'POS' &&
										item.cardType == '1'
								"
							>
								(津贴：{{ costList.allowanceCost }}%)</template
							>
						</td>
						<td>
							<CostInput
								v-model="item.t0Rate"
								:minVal="item.t0MinRate"
								:name="'t0MinRate' + item.id"
								@getStatus="getStatus"
								:maxVal="item.t0MaxRate"
							></CostInput>
							<template
								v-if="
									costList.allowanceCost &&
										item.payTypeCode == 'POS' &&
										item.cardType == '1'
								"
							>
								(津贴：{{ costList.allowanceCost }}%)</template
							>
						</td>
						<td>
							<div
								v-if="
									item.payTypeCode == 'POS' &&
										item.cardType == '1'
								"
							>
								<CostInput
									v-model="item.cashFee"
									:minVal="item.minCashFee"
									:name="'cashFee' + item.id"
									@getStatus="getStatus"
									:maxVal="item.maxCashFee"
								></CostInput>
							</div>
							<span v-else>-</span>
						</td>
						<td>
							<span @click="handlerConfig" class="peizhi">
								{{ item.cardType == 1 ? "配置" : "" }}
							</span>
						</td>
					</tr>
				</table>
			</div>
		</template>
		<!-- 配置模态框 -->
		<el-dialog
			:visible.sync="dialogTableVisible"
			append-to-body
			width="1091px"
		>
			<el-table :data="gridData" border>
				<el-table-column
					prop="adjustRangeName"
					label="调价区间"
					width="130"
					align="center"
				>
				</el-table-column>
				<el-table-column
					prop="minRate"
					label="费率下限(不含)"
					width="150"
					align="center"
				>
					<template v-slot="{ row, $index }">
						<input
							type="text"
							v-model="row.minRate"
							:class="[
								'costRate',
								'el-input__inner',
								row.canModifyRate == '0' ? 'inputDisable' : '',
								$index ? 'inputDisable' : ''
							]"
							:disabled="row.canModifyRate == '0' ? true : false"
						/>
						<!-- getDisabled($index) ? 'feilvxiaxian' : '' -->
						<!-- :disabled="!row.canModifyRate" -->
						<!-- <CashBackInput v-model="row.minRate" :name="'minRate' + row.minRate" @getStatus="getStatus2(row, $index)" :minVal="row.minRate" :maxVal="row.maxRate" > </CashBackInput> -->
					</template>
				</el-table-column>
				<el-table-column
					prop="maxRate"
					label="费率上限(包含)"
					width="150"
					align="center"
				>
					<template v-slot="{ row, $index }">
						<input
							type="text"
							v-model="row.maxRate"
							:class="[
								'costRate',
								'el-input__inner',
								row.canModifyRate == '0' ? 'inputDisable' : ''
							]"
							@input="changeVal(row, $index)"
							@blur="handerMaxRate(row.maxRate, $index)"
							:disabled="row.canModifyRate == '0' ? true : false"
						/>
						<!-- <CashBackInput v-model="row.maxRate" :name="'maxRate' + row.maxRate" @getStatus="getStatus2(row, $index)" :minVal="row.minRate" :maxVal="row.maxRate" > </CashBackInput> -->
					</template>
				</el-table-column>
				<el-table-column
					prop="profitProportion"
					label="分成比例(%)"
					width="170"
					align="center"
				>
					<template v-slot="{ row, $index }">
						0≤&nbsp;<input
							type="text"
							:style="isRed ? 'border-color:red' : ''"
							v-model="row.profitProportion"
							class="costRate el-input__inner" 
							:disabled="row.canModifyProportion == '0' ? true : false"
							@blur="
								handerProportion(row.profitProportion, $index)
							"
						/>&nbsp;≤{{ row.parentProfitProportion }}
						<!-- <CostInput v-model="row.profitProportion" :name="'profitProportion ' + row.profitProportion" @getStatus="handleProportion(row)" :minVal="0" :maxVal="100" @click="handleProportion" ></CostInput> -->
					</template>
				</el-table-column>
				<el-table-column
					prop="rateModifyAuthority"
					label="费率修改权限"
					width="150"
					align="center"
				>
					<template v-slot="{ row, $index }">
						<!--
							value设置当前表单的值，默认绑定布尔值
							active-color：设置选中状态的背景颜色
							inactive-color：设置未选中状态的背景颜色
							active-value：设置选中状态的值  --- 可以是数字 字符串 布尔值
							inactive-value：设置未选中状态的值  --- 可以是数字 字符串 布尔值
						-->
						<el-switch
							v-model="row.rateModifyAuthority"
							active-color="#34bd12"
							active-value="1"
							inactive-value="0"
							@change="handleRateModify(row, $index)"
						/>
					</template>
				</el-table-column>
				<el-table-column
					prop="profitProportionAuthority"
					label="分成权限下放"
					width="150"
					align="center"
				>
					<template v-slot="{ row, $index }">
						<el-switch
							v-model="row.profitProportionAuthority"
							active-color="#34bd12"
							active-value="1"
							inactive-value="0"
							nyon
							@change="handleDownAuthority(row, $index)"
						/>
					</template>
				</el-table-column>
				<el-table-column
					prop="adjustProfitAuthority"
					label="调价分成开关"
					width="150"
					align="center"
				>
					<template v-slot="{ row, $index }">
						<el-switch
							v-model="row.adjustProfitAuthority"
							active-color="#34bd12"
							active-value="1"
							inactive-value="0"
							@change="handleAdjustProfit(row, $index)"
						/>
					</template>
				</el-table-column>
			</el-table>
			<div class="addIcon">
				<i
					class="el-icon-circle-plus-outline"
					@click="addNewPolicy"
					v-if="isOrg"
				></i>
			</div>
			<span slot="footer" class="dialog-footer" align="center">
				<el-button type="primary" @click="editNewPolicy"
					>保存</el-button
				>
				<el-button @click="dialogTableVisible = false">关闭</el-button>
			</span>
			<div>
				<p>操作提示：</p>
				<p>
					1.
					费率修改权限关闭后，不允许下级直属代理商把他直属商户的费率调价到该区间。
				</p>
				<p>
					2.
					分成权限关闭后，不允许直属代理商给他的下级配置该费率区间的分成比例。
				</p>
				<p>
					3.
					调价分成开关关闭后，直属代理商将不再享受该费率区间的分成收益。
				</p>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import CostInput from "@/components/costInput";
import { ProfitManage } from "@/api";
import { mapState } from "vuex";

export default {
	data() {
		return {
			costList2: {},
			showBox: false,
			checked: false,
			disabled: "",
			gridData: [], // 表格列表数据
			dialogTableVisible: false, // 模态框默认值
			RateModifyDisabled: false, // 费率上限输入框禁用
			AdjustProfitDisabled: false, // 分成比例输入框禁用
			isRed: false // 表单提示红边框
		};
	},
	computed: {
		...mapState("app", ["userInfo"]),
		isOrg() {
			return this.userInfo.org;
		}
	},
	props: {
		costList: {
			type: [Array, String, Object]
		},
		isUpdate: {
			type: Boolean,
			default: false
		},
		canChangeTaxPoint: {
			type: Boolean,
			default: false
		},
		agentInfo: {
			type: Object
		}
	},
	components: {
		CostInput
	},
	methods: {
		change() {
			this.$emit("on-change", this.costList.checked, this.costList);
		},
		getStatus(name, status) {
			this.$emit("on-change", this.checked, this.costList, name, status);
		},
		// 配置详情列表数据
		async getConfigList() {
			const result = await ProfitManage.configDetail.getConfigDetail(
				this.agentInfo.agentNo,
				this.costList.policyNo
			);
			this.gridData = result.data;
			console.log("getConfigDetail----->348", result);
		},
		// 调价分成配置事件
		async handlerConfig() {
			this.dialogTableVisible = true;
			this.getConfigList();
		},
		// 添加列表数据方法
		async addNewPolicy() {
			// 表格列表数据
			const newData = {
				adjustRangeName: this.gridData.length + 1,
				//获取最后一条数据的maxRate
				minRate: this.gridData[this.gridData.length - 1].maxRate,
				maxRate: this.gridData[this.gridData.length - 1].maxRate,
				profitProportion: 1,
				rateModifyAuthority: "0",
				profitProportionAuthority: "0",
				adjustProfitAuthority: "0"
			};
			this.gridData.push(newData);
		},
		// 费率上限修改事件
		changeVal(row, index) {
			console.log("391----->", row, index);
			// 判断是否是存在+1，是则执行
			if (this.gridData[index + 1]) {
				this.gridData[index + 1].minRate = row.maxRate;
			}
		},
		// 保存修改事件
		async editNewPolicy() {
			// 获取表格数据
			const inputDataList = this.gridData.map(item => ({
				id: item.id,
				adjustRangeName: item.adjustRangeName,
				adjustProfitAuthority: item.adjustProfitAuthority,
				minRate: item.minRate,
				maxRate: item.maxRate,
				profitProportion: item.profitProportion,
				profitProportionAuthority: item.profitProportionAuthority,
				rateModifyAuthority: item.rateModifyAuthority
			}));
			inputDataList.forEach((item)=>{
				if(item.profitProportion=='' || item.profitProportion==undefined){
					item.profitProportion=0;
				}
			});
			console.log("inputDataList----->402", inputDataList);
			// 调用修改接口传递数据
			const result = await ProfitManage.configDetail.editSaveConfig({
				agentNo: this.agentInfo.agentNo,
				policyNo: this.costList.policyNo,
				agentAdjustProfitConfigDtoList: inputDataList
			});
			console.log("editSaveConfig----->405", result);
			if (result.success === true) {
				this.$message.success("保存成功");
			}
			// 关闭模态框
			this.dialogTableVisible = false;
		},
		// 修改费率上限事件
		handerMaxRate(eventCoutn, index) {
			// 校验小数不能超过两位
			const regex = /^\d+(\.\d{1,2})?$/;
			// 判断如果超过两位提示
			if (!regex.test(eventCoutn)) {
				this.$message.error("不能超过两位小数");
				// 清空输入框的数据
				this.gridData[index].maxRate = "";
			}
		},
		// 修改分成比例事件
		handerProportion(eventCoutn, index) {
			if(eventCoutn==''){
				this.$message.error('分成比例不能为空');
				return;
			}
			if (eventCoutn < 0) {
				this.$message.error("不能小于最小值");
				// 清空输入框的数据
				this.gridData[index].profitProportion = "";
				this.clearInputData();
			} else if (eventCoutn > 100) {
				this.$message.error("不能大于最大值");
				// 清空输入框的数据
				this.gridData[index].profitProportion = "";
			}
		},
		// 费率修改权限事件
		handleRateModify(row) {
			this.gridData.rateModifyAuthority = row;
		},
		// 分成权限下放事件
		handleDownAuthority(row) {
			this.gridData.profitProportionAuthority = row;
		},
		// 调价分成开关事件
		handleAdjustProfit(row) {
			this.gridData.adjustProfitAuthority = row;
		}
		// 费率下限解除禁用第一个按钮
		// getDisabled(index) {
		// 	return index !== 0;
		// }
	}
};
</script>
<style lang="scss" scoped>
.peizhi {
	color: #409eff;
	cursor: pointer;
}
::v-deep .el-dialog__footer {
	text-align: center;
}
// .my-custom-dialog .el-dialog__wrapper {
// 	z-index: 9999 !important; /* 使用 !important 以确保覆盖其他规则 */
// }
.addIcon {
	margin-top: 20px;
	text-align: center;
	.el-icon-circle-plus-outline {
		font-size: 30px;
		color: #eea94b;
	}
}
.costRate {
	width: 80px;
}
.inputDisable {
	background-color: #f5f7fa;
	border-color: #e4e7ed;
	color: #c0c4cc;
	cursor: not-allowed; //鼠标悬停显示禁用效果
}
</style>
